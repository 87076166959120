import React, { useState } from 'react'
import { motion, AnimatePresence } from 'framer-motion';
import { Link } from "gatsby"

// Hooks & Context
import { useSiteState } from '../../lib/siteContext';
// import { useScrollFreeze } from '../../hooks';
import { useSanityPath } from "../../hooks"

// Components
// import { NavLink } from './navLink';

// SVG
import { FatLogo } from "../ui"
// Animation
const navVariants = {
  closed: {
    opacity: 0,
    transition: {
      duration: 0.2,
    },
  },
  open: {
    opacity: 1,
    transition: {
      duration: 0.3,
    },
  },
};

const ParentLink = ({ closeNav, _key, path, external, target, title, link_type, links }) => {    
  const pageLink = useSanityPath(target)

  const [open, toggleOpen] = useState(false);

    return (
      <div key={_key} className="my-2 relative">
        {links.length >= 1  ? (
         <>
          <div className="parent-link" onClick={toggleOpen}>{title}</div>
          <div className={`sub-nav flex-col text-base pl-6 ${open ? 'flex' : 'hidden'}`}>
          {links.map((sublink, index) => (
            <SubLink index={index} {...sublink} closeNav={closeNav}/>
          ))}
          </div>
         </>
        ) : (
          <>
          {link_type === 'page' && <Link to={pageLink} className="parent-link" partiallyActive={true} activeClassName="active underline" onClick={() => closeNav()}>{title}</Link>}
          {link_type === 'path' && <Link to={`/${path}`} className="parent-link" partiallyActive={true} activeClassName="active underline" onClick={() => closeNav()}>{title}</Link>}        
          {link_type === 'external' && <a href={external} target="_blank" rel="noreferrer" className="parent-link" onClick={() => closeNav()}>{title}</a>}        
          </>
        )}
      </div>
    )
}

const SubLink = ({ closeNav, link_type, path, zingfit, external, title, target, _key }) => {
  const pageLink = useSanityPath(target)
    return (
      <>
      {link_type === 'page' && <Link key={_key} to={pageLink} className="mr-8"  activeClassName="active underline" >{title}</Link>}
      {link_type === 'path' && <Link key={_key} to={`/${path}`} className="mr-8" activeClassName="active underline" >{title}</Link>}        
      {link_type === 'zingfit' && <a key={_key} href={`/${zingfit}`} className="mr-8">{title}</a>}        
      {link_type === 'external' && <a key={_key} href={external} target="_blank" rel="noreferrer" className="mr-8">{title}</a>}        
      </>
      )
}

const NavInner = ({ mainNav, navOpen }) => {
  //useScrollFreeze();
  const { closeNav } = useSiteState();
  // const cartCount = useCartCount();

  return (
    <motion.div
      className={`h-screen mobile-nav w-screen fixed top-0 bottom-0 left-0 z-2000 pl-10 bg-black bg-opacity-30 trans-std transform ${navOpen ? '' : 'translate-x-full'}`}
      initial="closed"
      animate="open"
      exit="closed"
      variants={navVariants}
    >
      <div className="h-full w-full bg-fat-blue text-white px-gutter py-4 flex flex-col justify-start">
        <FatLogo classes="w-1/2 h-auto mb-gutter" />
        <nav id="mobile-nav" className="flex flex-col uppercase heading">
          {mainNav.sections.map((section, index) => (            
            <ParentLink closeNav={closeNav} {...section} />                                  
          ))}
          <a href="https://ondemand.fitnessalltogether.co.nz/" target="_blank" rel="noreferrer" className="text-h6 mt-8">FAT On Demand</a>
          <a href="/fat-workout/timetable/#/account" className="my-4 text-h6">My Account</a>          
          <div className="flex">          
          <a href="/fat-workout/timetable" className="mr-4 btn-rounded-mobMenu">Book Now</a>
          <a onClick={() => closeNav()} href="/fat-workout/timetable/#/pricing" className="btn-rounded-mobMenu">Buy Classes</a>
          </div>          
        </nav>      
        <div 
          className="heading text-h5 absolute top-4 right-4 leading-none z-10"
          tabIndex={0} 
          role="button" 
          onClick={() => closeNav()} 
          onKeyDown={() => closeNav()}
        >X</div>
      </div>      
    </motion.div>
  );
};

export const MobileNav = ({ mainNav }) => {
  const { navOpen } = useSiteState();
  return <AnimatePresence><NavInner navOpen={navOpen} mainNav={mainNav} /></AnimatePresence>;
};

